import React, { useCallback, useEffect, useState } from "react";
import { usePluginContext } from "../../../../providers/contextManagement";
import { isZendesk } from "../../../../constants/plugin_variables";
import { Button } from "@frontapp/ui-kit";
import { login } from "../../api/api";
import {
  ignite_url,
  portalUrl,
  integration_name as integrationName,
} from "../../constants/variables";

import WorkflowSvg from "../../assets/images/workflow.svg";
import DocumentSkillsSvg from "../../assets/images/document-ocr.svg";
import ResponseAssistSvg from "../../assets/images/response-assist.svg";
import UpBrainsLogo from "../../assets/images/log-white.svg";

import styles from "./NewLanding.module.css";
import { BeatLoader } from "react-spinners";

const NewLanding = () => {
  const [error, setError] = useState(false);
  const [hasContext, setHasContext] = useState(false);
  const [email, setEmail] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [isLoading, setLoading] = useState(false);

  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  useEffect(() => {
    if (localStorage.getItem("origin")) {
      let origin = localStorage.getItem("origin");
      origin = origin.replace("https://", "");
      origin = origin.replace(".zendesk.com", "");
      setSubdomain(origin);
    }
  }, [localStorage.getItem("origin")]);

  const handleLogin = useCallback(async () => {
    try {
      setLoading(true);
      if (context) {
        const loginStatus = await login(context, integrationName);
        if (loginStatus !== "authorized") throw new Error(loginStatus);
      }
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [context]);

  const handleGetStarted = () => {
    window.open(
      !isZendesk
        ?  "https://workspace.upbrains.ai/sign-up" // `${ignite_url}/front_app_login?email=${email}`
        :  "https://workspace.upbrains.ai/sign-up" // `${ignite_url}/zendesk_login?email=${email}&subdomain=${subdomain}`
    );
  };

  useEffect(() => {
    if (context) {
      setHasContext(true);
      setEmail(context?.teammate?.email);
    } else {
      setHasContext(false);
    }
  }, [context]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <div className={styles.logoImg}>
            <img alt="UpBrains AI" src={UpBrainsLogo} />
          </div>
          <h3 className={styles.logoText}>UpBrains AI</h3>
        </div>
        <div className={styles.title}>
          UpBrains AI Copilot for {isZendesk ? "Zendesk" : "Front"}
        </div>
        <div className={styles.description}>
          Try UpBrains AI for free with a
          <br />
          <a
            className={styles.link}
            href={
              !isZendesk
                ? "https://workspace.upbrains.ai/sign-up" //`${portalUrl}/front_app_login?email=${email}`
                : "https://workspace.upbrains.ai/sign-up" //`${portalUrl}/zendesk_login?email=${email}&subdomain=${subdomain}`
            }
            target="_blank"
            rel="noreferrer"
          >
            14-day Free
          </a>{" "}
          trial subscription.
        </div>
      </div>
      <div className={styles.marketingContainer}>
        <div className={styles.marketing}>
          <div className={styles.marketingCards}>
            <div className={styles.marketingCard}>
              <div className={styles.marketingCardImg}>
                <img alt="UpBrains Workflow" src={WorkflowSvg} />
              </div>
              <p className={styles.marketingCardText}>
                {isZendesk ? (
                  <>
                    Automate all your helpdesk workflows with{" "}
                    <strong>AI-powered workflow</strong> automation!
                  </>
                ) : (
                  <>
                    Automate all your inbox workflows with{" "}
                    <strong>AI-powered workflow</strong> automation from
                    UpBrains AI!
                  </>
                )}
              </p>
            </div>
            <div className={styles.marketingCard}>
              <div className={styles.marketingCardImg}>
                <img alt="UpBrains Documents Skills" src={DocumentSkillsSvg} />
              </div>
              <p className={styles.marketingCardText}>
                {isZendesk ? (
                  <>
                    <strong>UpBrains Documents Skills</strong> for intelligent
                    OCR and information extraction from any document types and
                    classification of tickets, comments and attachments.
                  </>
                ) : (
                  <>
                    <strong>UpBrains Documents Skills</strong> for intelligent
                    OCR and information extraction from any document types and
                    classification of messages and attachments..
                  </>
                )}
              </p>
            </div>
            <div className={styles.marketingCard}>
              <div className={styles.marketingCardImg}>
                <img alt="UpBrains Response Assist" src={ResponseAssistSvg} />
              </div>
              <p className={styles.marketingCardText}>
                {isZendesk ? (
                  <>
                    <strong>UpBrains Response Assist</strong> automated response
                    generation from your own knowledge bases, APIs and systems,
                    sentiment analysis, ticket tagging and more with ChatGPT
                    inside!
                  </>
                ) : (
                  <>
                    <strong>UpBrains Response Assist</strong> automated response
                    generation from your own knowledge bases, APIs and systems,
                    sentiment analysis, conversation tagging and more with
                    ChatGPT inside!
                  </>
                )}
              </p>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={handleLogin}
              type={error.message ? "primary-danger" : "primary"}
              size="medium"
              isDisabled={!hasContext}
            >
              {isLoading ? (
                <div className="new-landing-1">
                  <BeatLoader
                    color={"#0059b3"}
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                "Login"
              )}
            </Button>
            {!!error && <p className="error-message">{error}</p>}
            <Button onClick={handleGetStarted} variant="outlined" size="medium">
              Get Started for Free
            </Button>
          </div>
        </div>
        <div className={styles.footer}>
          <div>&copy; 2024 UpBrains AI Inc.</div>
          <a
            href={
              isZendesk
                ? "https://community.upbrainsai.com/docs/product-walk-through/upbrains-ai-copilot/zendesk-app-plugin/"
                : "https://community.upbrainsai.com/docs/quick-start-guide/"
            }
            target="_blank"
            rel="noreferrer"
          >
            Startup Guide
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewLanding;
